<template>
  <div class="b-kasbon pb-5">
    <b-row no-gutters class="m-0">
      <b-col sm="12" class="p-3">
        <router-link to="/kasbon/form">
          <b-button variant="primary" block class="w-100 button-sag f-11 bold"
            >Ajukan Kasbon</b-button
          >
        </router-link>
      </b-col>
    </b-row>
    <b-row no-gutters class="m-0">
      <b-col xs="12" class="p-3 f-13" style="font-weight: bold">
        <b-form-group label="Tahun">
          <b-form-select
            :options="opt.tahun"
            class="w-100 f-13"
            v-model="kasbon.filter.tahun"
            @change="getHistoriKasbon()"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row no-gutters class="m-0">
      <b-col sm="12" class="p-3">
        <div class="bd-card rounded-3">
          <div class="p-3 d-flex align-items-center f-14 bold">
            <b-icon icon="journal-check" class="text-primary mr-2" />
            <i class="bi bi-journal-check"></i> Histori Kasbon
          </div>
          <div class="p-2">
            <div
              class="d-flex justify-content-center align-items-center flex-column p-5"
              v-if="kasbon.loading"
            >
              <b-spinner
                class="f-12"
                type="grow"
                label="Spinning"
                style="background-color: #159895"
              ></b-spinner>
              <span class="f-11 mt-1 bold">Loading....</span>
            </div>

            <div v-else>
              <div
                class="p-2 text-white mb-2"
                :class="kasbon.bg"
                v-for="(kasbon, kasbonidx) in kasbon.history"
                :key="`kasbon-${kasbonidx}`"
                style="border-radius: 5px"
              >
                <div class="d-flex justify-content-between">
                  <h6 class="m-0 p-0">Rp {{ thousand(kasbon.jml_kasbon) }}</h6>
                  <h6 class="f-13 bold">{{ kasbon.status_kasbon }}</h6>
                </div>
                <span class="f-11">{{ kasbon.keterangan }}</span>
                <div class="d-flex justify-content-between mt-3 f-11">
                  <label class="m-0 p-0">{{ kasbon.tgl_pengajuan }}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<style>
.f-11 {
  font-size: 11px;
}
.f-12 {
  font-size: 12px;
}
.bold {
  text-transform: uppercase;
  font-weight: bold;
}
.f-13 {
  font-size: 13px;
}
.f-14 {
  font-size: 14px;
}
.bg-card-disetujui {
  border: none;
  background-color: #159895 !important;
}
.bg-card-pending {
  border: none;
  background-color: #e55807;
}
.bg-card-ditolak {
  border: none;
  background-color: #b70404;
}
.button-sag {
  background-color: #159895;
  border: none;
  text-transform: uppercase;
}
</style>
<script>
import { mapGetters } from "vuex";
import axios from "@/axios";
export default {
  data() {
    return {
      kasbon: {
        history: [],
        filter: {
          tahun: new Date().getFullYear(),
        },
        loading: false,
      },
      opt: {
        tahun: [],
      },
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    async getHistoriKasbon() {
      this.kasbon.loading = true;
      let url = `presence/list_kasbon`;
      let header = {
        headers: {
          Token: this.user.token,
        },
      };
      let payload = new FormData();
      for (let key in this.kasbon.filter) {
        payload.append(key, this.kasbon.filter[key]);
      }
      payload.append("nik", this.user.data.nik);
      let req = await axios.post(url, payload, header);
      if (req.data.status) {
        let kasbon = [];
        req.data.msg.map((x) => {
          switch (x.status_kasbon) {
            case "Regis":
              x.bg = "bg-card-pending";
              break;
            case "Ditolak":
              x.bg = "bg-card-ditolak";
              break;
            default:
              x.bg = "bg-card-disetujui";
              break;
          }
          kasbon.push(x);
        });
        this.kasbon.history = kasbon;
        this.kasbon.loading = false;
      } else {
        this.kasbon.loading = false;
      }
    },
    thousand(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
  mounted() {
    this.getHistoriKasbon();
    let thisYear = new Date().getFullYear() + 1;
    for (let x = thisYear; x > 1990; x--) {
      let tahun = {
        value: x,
        text: x,
      };
      this.opt.tahun.push(tahun);
    }
  },
};
</script>
